<template>
  <el-steps :active="activeStep"
            process-status="finish"
            finish-status="finish"
            align-center>
    <el-step title="申请须知"></el-step>
    <el-step title="提交申请资料"></el-step>
    <el-step title="平台审核"></el-step>
    <el-step title="申请成功"></el-step>
  </el-steps>
</template>

<script>
export default {
  name: 'orgApplySteps',
  data () {
    return {
      activeStepData: this.activeStep
    };
  },
  props: {
    activeStep: {
      type: Number,
      default: 0
    }
  },
  watch: {
    activeStep (newVal) {
      this.activeStepData = newVal;
    }
  }
};
</script>

<style scoped>
/*修改“等待”状态步骤点的背景颜色*/
/deep/.is-wait .el-step__icon {
  background-color: #DBE1EB;
}
/*修改步骤点字体的颜色*/
/deep/.el-step__icon-inner {
  color: #fff;
}
/*修改“完成”状态步骤点的背景颜色*/
/deep/.is-finish .el-step__icon {
  background-color: #156ED0;
}
/*修改步骤点是否有边框*/
/deep/.is-text {
  border: none;
}
/*修改步骤点的宽高*/
/deep/.el-step__icon {
  width: 30px;
  height: 30px;
}
/*修改步骤条步骤连线*/
/deep/.el-step__line {
  height: 1px !important;
  top: 15px !important;
  background-color: #DBE1EB;
}
/*修改步骤条描述*/
/deep/.el-step__title {
  font-weight: bold;
}
</style>
